.theme_card {
  width: 100%;
  min-width: 250px;
  max-width: 300px;
  height: 14.5rem;
}
.theme-image-container {
  overflow: hidden;
  height: 150px;
}

.theme_card > .apply-theme-section {
  display: none;
}
.theme_card:hover > .apply-theme-section {
  display: block;
  position: absolute;
  background-color: rgb(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
}
.apply-btn {
  background-color: white;
  width: 100%;
}
.box-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: #1e17b6;
}
