.order_cards {
  max-width: 48% !important;
  height: 180px;
}
@media only screen and (max-width: 568px) {
  .order_cards {
    max-width: 14rem !important;
    height: 130px;
  }
}

/* TABLE MAIN CONTAINER */

.table_main_container {
  position: relative;
  overflow-x: auto;
  min-width: 280px;
  width: 100%;
  scrollbar-width: thin;
  border: 0.119rem solid #e6ecff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table_main_container_error {
  position: relative;
  overflow-x: auto;
  min-width: 280px;
  width: 100%;
  border: 0.119rem solid #f24444;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table_main_container::-webkit-scrollbar {
  background-color: #fbfbfb;
  width: 8px;
  height: 5px;
}
.table_main_container::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
}
.table_main_container_error::-webkit-scrollbar {
  background-color: #fbfbfb;
  width: 8px;
  height: 10px;
}
.table_main_container_error::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
}
