body {
  font-family: "Poppins", sans-serif !important;
}
.font_montserrat {
  font-family: "Montserrat", sans-serif;
}
.font_poppins {
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none !important;
}
input[type="password"] {
  font-family: Verdana;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: url("../src/assets/icons/date-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: -2px;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: url("../src/assets/icons/date-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: -2px;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: url("../src/assets/icons/time-icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: -2px;
}
input[type="time"]::placeholder {
  content: "Select Time";
}

.fw_200 {
  font-weight: 200;
}
.fw_300 {
  font-weight: 300;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fw_800 {
  font-weight: 800;
}
.fw_900 {
  font-weight: 900;
}

.fs_10 {
  font-size: 10px;
}
.fs_12 {
  font-size: 12px;
}
.fs_13 {
  font-size: 13px;
}
.fs_14 {
  font-size: 14px;
}
.fs_15 {
  font-size: 15px;
}
.fs_16 {
  font-size: 16px;
}
.fs_17 {
  font-size: 17px;
}
.fs_18 {
  font-size: 18px;
}
.fs_19 {
  font-size: 19px;
}
.fs_20 {
  font-size: 20px;
}
.fs_21 {
  font-size: 21px;
}
.fs_22 {
  font-size: 22px;
}
.fs_24 {
  font-size: 24px;
}
.fs_26 {
  font-size: 26px;
}
.fs_28 {
  font-size: 28px;
}
.fs_30 {
  font-size: 30px;
}
.fs_32 {
  font-size: 32px;
}
.fs_34 {
  font-size: 34px;
}
.fs_36 {
  font-size: 36px;
}
.primary_color {
  color: #2820da;
}
.secondary_color {
  color: #e5ecff;
}

.color_F9F9FC {
  color: #f9f9fc;
}

.color_E6E6E6 {
  color: #e6e6e6;
}
.color_04BA71 {
  color: #04ba71;
}
.color_0064D2 {
  color: #0064d2;
}
.color_F2C025 {
  color: #f2c025;
}
.color_2A22E5 {
  color: #2a22e5;
}
.color_34BF44 {
  color: #34bf44;
}
.color_ffc107 {
  color: #c29c25;
}
.color_F23D3D {
  color: #f23d3d;
}
.color_FF6C1A {
  color: #ff6c1a;
}
.color_C8C8C8 {
  color: #c8c8c8;
}
.color_9E9E9E {
  color: #9e9e9e;
}
.color_707070 {
  color: #707070;
}
.color_FFFFFF {
  color: #ffffff;
}
.color_444444 {
  color: #444444;
}

.color_030304 {
  color: #030304;
}
.color_F7D300 {
  color: #f7d300;
}

.color_3AD64C {
  color: #3ad64c;
}
.color_F24444 {
  color: #f24444;
}
.color_FF6F1E {
  color: #ff6f1e;
}
.color_gray1 {
  color: #444444;
}
.color_2820DA {
  color: #2820da;
}
.focus_outline__none:focus-visible {
  outline: none;
}
.bg_color_F3F6FF {
  background-color: #f3f6ff;
}
.bg_color_F9F9FC {
  background-color: #f9f9fc;
}
.bg_color_E5ECFF {
  background-color: #e5ecff;
}
.bg_color_E5ECFFB2 {
  background-color: #e5ecffb2;
}
.bg_color_E5ECFF80 {
  background-color: #e5ecff80;
}
.bg_color_2820DA {
  background-color: #2820da;
}
.bg_color_34BF441A {
  background-color: #34bf441a;
}
.bg_order {
  background-color: #e5ecff4d;
}
.bg_color_F23D3D1A {
  background-color: #f23d3d1a;
}
.bg_color_3AD64C {
  background-color: #3ad64c;
}
.bg_color_ffffff {
  background-color: #ffffff;
}
.bg_color_E6ECFF {
  background: #e6ecff;
}
.bg_color_09073A0D {
  background: #09073a0d;
}
.bg_color_F24444 {
  background: #f24444;
}
.bg_color_3AD64C_opacity_1 {
  background: rgba(58, 214, 76, 0.1);
}
.bg_color_F24444_opacity_1 {
  background: rgba(242, 68, 68, 0.1);
}
.bg_table {
  background: rgba(249, 249, 252);
}

.cursor_pointer {
  cursor: pointer;
}
.border_1px_A0A0BF {
  border: 1px solid #a0a0bf;
}
.border_1px_E6ECFF {
  border: 1px solid #e6ecff;
}

.border_1px_e5ecff {
  border: 1px solid #e5ecff;
}
.border_bottom_1px_e5ecff {
  border-bottom: 1px solid #e5ecff;
}
.border_top_1px_e6ecff {
  border-top: 1px solid #e6ecff;
}
.border_1px_red {
  border: 1px solid red;
}
.border_2px_2820DA {
  border: 2px solid #2820da;
}
.border_1px_F24444 {
  border: 2px solid #f24444;
}
.border_bottem_1px {
  border-bottom: 1px solid #e5ecff;
}
.border_05px_E6ECFF {
  border: 0.5px solid #e6ecff;
}
.border_radius_5px {
  border-radius: 5px;
}
.border_radius_7px {
  border-radius: 7px;
}
.border_radius_10px {
  border-radius: 10px;
}
.box_shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
button {
  border: 0;
  background-color: transparent;
}
@media only screen and (max-width: 1200px) {
  .fs_lg_12 {
    font-size: 12px;
  }
  .fs_lg_14 {
    font-size: 14px;
  }
  .fs_lg_16 {
    font-size: 16px;
  }
  .fs_lg_18 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 992px) {
  .fs_md_10 {
    font-size: 10px;
  }
  .fs_md_12 {
    font-size: 12px;
  }
  .fs_md_14 {
    font-size: 14px;
  }
  .fs_md_16 {
    font-size: 16px;
  }
  .fs_md_18 {
    font-size: 18px;
  }
  .fs_md_20 {
    font-size: 20px;
  }
  .fs_md_22 {
    font-size: 22px;
  }
  .fs_md_24 {
    font-size: 24px;
  }
  .fs_md_26 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 576px) {
  .fs_xs_12 {
    font-size: 12px;
  }
  .fs_xs_14 {
    font-size: 14px;
  }
  .fs_xs_16 {
    font-size: 16px;
  }
  .fs_xs_18 {
    font-size: 18px;
  }
  .fs_xs_24 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .fs_sm_12 {
    font-size: 12px;
  }
  .fs_sm_14 {
    font-size: 14px;
  }
  .fs_sm_16 {
    font-size: 16px;
  }
  .fs_sm_20 {
    font-size: 20px;
  }
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.input_border {
  border: 1px solid #c8c8c8;
}
input[type="text"]:disabled {
  background-color: #f9f9fc;
  border: none;
}
input[type="checkbox"] {
  background-color: #e6e6e670;
  border-color: #9e9e9e;
}
input[type="checkbox"]:focus {
  box-shadow: none !important;
  border-color: #9e9e9e;
}
input[type="checkbox"]:checked {
  background-color: #2820da;
  border: none;
}
input[type="checkbox"]:disabled {
  background-color: #e6e6e6;
  border-color: #9e9e9e;
}
input[type="checkbox"]:checked:disabled {
  background-color: #2820da;
  border: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.vh65 {
  height: 65vh;
}
.w_fit {
  width: fit-content;
}

@keyframes fadeOutToLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.my-element {
  animation: fadeOutToLeft 2s forwards;
}
.custom-dropdown-indicator {
  padding: 6px !important;
}

.line_height {
  line-height: 1px;
}
