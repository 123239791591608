/* TABLE MAIN CONTAINER */

.table_main_container {
  position: relative;
  overflow-x: auto;
  min-width: 280px;
  width: 100%;
  scrollbar-width: thin;
  border: 0.119rem solid #e6ecff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table_main_container_error {
  position: relative;
  overflow-x: auto;
  min-width: 280px;
  width: 100%;
  border: 0.119rem solid #f24444;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.table_main_container::-webkit-scrollbar {
  background-color: #fbfbfb;
  width: 8px;
  height: 10px;
}
.table_main_container::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
}
.table_main_container_error::-webkit-scrollbar{
  background-color: #fbfbfb;
  width: 8px;
  height: 10px;
}
.table_main_container_error::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
}
/* TABLE CONTAINER */

.table_container {
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  /* border: 2px solid #e6ecff; */
  border-radius: 3px;
  width: 100%;
  background-clip: padding-box;
}

/* TABLE HEADING SECTION */
.table_heading_container {
  display: table-header-group;
}
/* .table_heading_border {
  border-bottom: 0.119rem solid #e6ecff;
}
.table_heading_border_error{
  border-bottom: 0.119rem solid  #f24444 ;
} */

.table_heading_row {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}

.table_heading_data {
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  /* border-bottom: 0.119rem solid #e6ecff; */
  text-align: left;
  background-color: #ffffff;
  color: #707070;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 16px;
}
.products_table_heading_data {
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  background-color: #ffffff;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 16px;
}

.products_table_heading_data:last-of-type {
  /* width: 40%; */
  background-clip: padding-box;
  /* text-align: right !important;
   */
  /* display: flex; */
  /* justify-content: right; */
}
.table_heading_data:last-of-type {
  /* width: 40%; */
  background-clip: padding-box;
  /* text-align: right !important;
   */
  display: flex;
  justify-content: right;
}
.table_Action_heading {
  width: 12rem;
}
.discounts_table_Action_heading {
  width: 9.2rem;
}

.table_Action_heading_without_edit_delete {
  width: 6rem;
}
.dis_table_heading_without_edit_delete {
  width: 4rem;
}
.table_customer_Action_heading {
  width: 17rem;
}
/* TABLE BODY SECTION */
.table_body_container {
  display: table-row-group;
}

.table_body_data {
  font-size: 13px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-top: 1px solid #e6ecff;
  text-align: left;
  padding: 15px 16px;
}
.dis_table_body_data {
  font-size: 13px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-top: 1px solid #e6ecff;
  text-align: left;
  padding: 15px 16px;
}
.table_Action_data_height {
  font-size: 13px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-top: 1px solid #e6ecff;
  text-align: left;
  padding: 15px 16px;
}
.table_Action_data_height:last-of-type {
  /* width: 40%; */
  background-clip: padding-box;
  /* text-align: right; */
  border-top: 1px solid #e6ecff;
  display: flex;
  justify-content: right;
  padding: 35px 16px;
}

.table_body_data:last-of-type {
  /* width: 40%; */
  background-clip: padding-box;
  /* text-align: right; */
  border-top: 1px solid #e6ecff;
  display: flex;
  justify-content: right;
}

/* checkbox */

.table_search_input:focus-visible {
  outline: none;
}
.table_search_input::placeholder {
  color: #9e9e9e;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.colored-line {
  border: 2px solid #e6e6e6;
  /* Replace with your desired color */
}

.sliderContainer {
  width: 100%;
}

.multiSlider {
  height: 5px;
  background: #ddd;
  border-radius: 5px;
}

.thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #2820da;
  cursor: pointer;
}

.side-bar-container {
  z-index: 9999 !important;
}
.custom_backdrop {
  z-index: 1500 !important;
}




