
.left_pointer {
  position: absolute;
  left: -8px;
  top: 50%;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #ffffff;
  box-shadow: "0px 0px 15px 0px #0000001A";
  transform: translateY(-50%);
}
.right_pointer {
  position: absolute;
  right: -8px;
  top: 50%;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 9px solid #ffffff;
  box-shadow: "0px 0px 15px 0px #0000001A";
  transform: translateY(-50%);
}

.outer_circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.inner_circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

  


@media only screen and (max-width: 568px){
  .order_cards_icons{
    width: 14px;
  } 

}
 
