.image_upload_container {
  height: 25rem;
  border: 1px solid #e5ecff;
}
.card_container {
  max-width: 15.7rem !important;
  height: 20.1rem;
}
.model_card_container {
  max-width: 12.7rem !important;
  height: 16rem !important;
}
.picture_border {
  max-width: 80.5rem;
}
.model_picture_container {
  max-width: 80.5rem;
}
.upload_image_container{
  height: 42rem;
  overflow-y: scroll;
}
@media only screen and (max-width: 1600px) {
  .picture_border {
    max-width: 64.5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .picture_border {
    max-width: 48.5rem;
  }
  .card_container {
    max-width: 16.7rem !important;
    height: 17rem !important;
  }
  .model_card_container {
    max-width: 14.7rem !important;
    height: 17rem !important;
  }
  .model_picture_container {
    max-width: 58rem;
  }
}
@media only screen and (max-width: 992px) {
  .model_picture_container {
    max-width: 45rem;
  }
}
@media only screen and (max-width: 768px) {
  .card_container {
    max-width: 13rem !important;
    height: 14rem !important;
  }
  .model_card_container {
    max-width: 13rem !important;
    height: 14.5rem !important;
  }
  .picture_border {
    max-width: 40.5rem;
  }
  .model_picture_container {
    max-width: 40rem;
  }
}
.add_card_container {
  max-width: 15rem !important;
  height: 21rem !important;
}
