.config_nav__container {
  border-right: 1px solid #e5ecff;
  width: 250px;
}
.config_section__container {
  width: calc(100% - 250px);
}
.config_list__item {
  max-width: 200px;
}
.config_content__container {
  min-height: 600px;
}
@media only screen and (max-width: 1200px) {
  .config_nav__container {
    width: 220px;
  }
  .config_list__item {
    font-size: 14px;
  }
  .config_section__container {
    width: calc(100% - 220px);
  }
}
@media only screen and (max-width: 768px) {
  .config_list__item {
    border: 2px solid #cfd8f0;
    max-width: 320px;
    font-size: normal;
  }
  .config_nav__container {
    width: 100%;
    border: none;
  }
  .config_section__container {
    width: 100%;
  }
  .config_content__container {
    min-height: 500px;
  }
}
