.delete_modal {
  max-width: 29rem !important;
}
.tag_modal_container {
  width: 100% !important;
  max-width: 40rem !important;
}
.attribute_modal_container {
  width: 100% !important;
  max-width: 37rem !important;
}
.variant_modal_container {
  width: 100% !important;
  max-width: 65rem !important;
}
.edit_variant_content_class {
  min-height: 50rem;
}
@media only screen and (min-width: 992px) {
  .discounts_add_products_modal {
    min-width: 65rem !important;
  }
}
.edit_address_model {
  width: 100% !important;
  max-width: 65rem !important;
}
.edit_order_refaund_model {
  width: 100% !important;
  max-width: 55rem !important;
}
.edit_payment_details_model {
  width: 100% !important;
  max-width: 35rem !important;
}
.add_delivery_details_model {
  width: 100% !important;
  max-width: 40rem !important;
}
.edit_product_model {
  width: 100% !important;
  max-width: 80rem !important;
}
.billing_details_model {
  width: 100% !important;
  max-width: 55rem !important;
}
