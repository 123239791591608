.metaInputField {
  min-height: 40px; 
  resize: none;
  border: 3px solid #e5ecff;
  scrollbar-width: thin; /* Set the width of the scrollbar */
  scrollbar-color: #e5ecff transparent;
  padding-left: 10px; /* Add padding to the entire textarea */
  padding-top: 6px;
}
.metaInputField:focus-visible {
  outline: none;
}

.metaInputField::-webkit-scrollbar {
  width: 0px; /* Set the width of the scrollbar */
}
.metaInputField::placeholder {
  color: #030304;
  font-weight: 600;
}

.metaInputField::-webkit-scrollbar-thumb {
  background-color: #e5ecff; /* Set the thumb color */
}

.meta_table_body_data {
  font-size: 13px;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-top: 1px solid #e6ecff;
  text-align: left;
  padding: 15px 10px;
}

.meta_table_body_data:last-of-type {
  /* width: 40%; */
  background-clip: padding-box;
  /* text-align: right; */
  border-top: 1px solid #e6ecff;
  display: flex;
  /* justify-content: right; */
  padding: 15px 10px;;
}

.products_table_heading_data {
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  background-color: #ffffff;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 10px;
}

.products_table_heading_data:last-of-type {
  /* width: 40%; */
  background-clip: padding-box;
  padding: 0px 0px;
  padding: 15px 10px;
  /* text-align: right !important;
     */
  /* display: flex; */
  /* justify-content: right; */
}
