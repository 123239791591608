/* Loading.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-bar {
  width: 200px; /* Adjust the width as needed */
  height: 20px;
  background-color: #f3f3f3; /* Light grey */
  border-radius: 10px;
  overflow: hidden;
}

.loading-progress {
  width: 100%;
  height: 100%;
  background-color: #3498db; /* Blue */
  animation: moveInside 2s linear infinite; /* Adjust the animation duration as needed */
}

@keyframes moveInside {
  0%,
  100% {
    margin-left: -100%;
  }
  50% {
    margin-left: 100%;
  }
}

.loading-label {
  margin-top: 10px;
  font-weight: bold;
}
