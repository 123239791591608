.navbar_container {
  border-bottom: 1px solid #e5ecff;
}

.profile_image_container {
  width: 42px;
  height: 42px;
  border: 2px solid #030304;
}

.main_layout_container {
  width: calc(100% - 250px);
}

.sidebar_container {
  width: 250px;
  border-right: 1px solid #e5ecff;
  height: 90vh;
}

.inner_layout_container {
  border-bottom: 1px solid#E6E6E6;
  top: 59px !important;
  z-index: 997 !important;
}

@media only screen and (max-width: 992px) {
  .main_layout_container {
    width: 100%;
  }

  .inner_layout_container {
    top: 50px !important;
  }
}