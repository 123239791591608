.inputField_border__error {
  padding: 0.39rem 0.8rem;
  border: 1.55px solid #f24444;
  border-radius: 5px;
}
.inputField_border {
  padding: 0.39rem 0.8rem;
  border: 1.55px solid #e5ecff;
  border-radius: 5px;
}
.inputField::placeholder {
  color: #c8c8c8;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  top: 25%;
}
.inputField {
  color: #030304;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.textAreaField {
  color: #030304;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.textAreaField::placeholder {
  color: #c8c8c8;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
}
.selectField_border__error {
  border: 1.55px solid #f24444;
  border-radius: 5px;
}
.selectField_border {
  border: 1.55px solid #e5ecff;
  border-radius: 5px;
}
.selectField {
  color: #030304;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
}
.unit_input__container {
  padding: 0.27rem 0.8rem 0.45rem 0.8rem;
  border: 1.55px solid #e5ecff;
  border-radius: 5px;
}
.unit_input_container__error {
  padding: 0.27rem 0.8rem 0.45rem 0.8rem;
  border: 1.55px solid #f24444;
  border-radius: 5px;
}
.unit_input__container:focus-visible {
  outline: none;
}
.input-vr {
  align-self: stretch;
  background-color: #e5ecff;
  width: 1.5px;
  box-sizing: border-box;
}

.radio-button:checked {
  background-color: #2820da;
  box-shadow: 0 0 0 1px #2820da;
  border: 3.6px solid #fff;
}
.radio-button {
  box-shadow: 0 0 0 1px #a5a5a5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: #f2f2f2;
  margin: 1px;
}
