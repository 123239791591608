.forgot_pass_container {
  background-image: url("../../assets/images/key-image.png");
  background-repeat: no-repeat;
  background-size: 58rem;
  background-position: left bottom;
}
.reset_pass_conainer {
  background-image: url("../../assets/images/local-image.png");
  background-repeat: no-repeat;
  background-size: 58rem;
  background-position: left bottom;
}
@media only screen and (max-width: 992px) {
  .forgot_pass_container {
    background-image: none;
  }
  .reset_pass_conainer {
    background-image: none;
  }
}
